<template>
  <div>
    <!-- Table -->
    <b-table
      id="contratosTable"
      v-if="getAvailableList"
      :fields="fields"
      :items="getResourceList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
    >
      <!-- Eliminado -->
      <template #cell(is_deleted)="row">
        <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
        <b-badge v-else variant="light">Sí</b-badge>
      </template>

      <!-- Nombre -->
      <template #cell(subcondominio)="row">
        <span :class="{ 'text-muted': row.item.is_deleted }">{{ row.item.subcondominio }}</span>
      </template>

      <template #cell(dueno)="row">
        <span :class="{ 'text-muted': row.item.is_deleted }">{{ row.item.dueno.persona.nombre_completo }}</span>
      </template>

      <template #cell(responsable)="row">
        <span :class="{ 'text-muted': row.item.is_deleted }">{{ row.item.responsable.persona.nombre_completo }}</span>
      </template>

      <!-- Detalle -->
      <template #cell(show_details)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Detalles
        </b-button>
      </template>

      <!-- Rentas -->
      <template #cell(show_rentas)="row">
        <b-button
          @click="showRentas(row.item.id)"
          variant="white"
          class="btn-sm mb-2 btn-block btn-outline-green"
        >
          Rentas
        </b-button>
      </template>

      <!-- Acciones -->
      <template #cell(acciones)="row">
        <div class="d-flex justify-content-center" v-if="!row.item.is_deleted">
          <u-d-controls
            :id="row.item.id"
            editPath="contratoModule/getResourceToEdit"
            deletePath="contratoModule/deleteResource"
            :editPermissions="['actualizar contratos']"
            :deletePermissions="['eliminar contratos']"
            @on-got-item="emitOnGotItem"
          ></u-d-controls>
        </div>
      </template>

      <!-- Acciones -->
      <template #row-details="row">
        <contrato-inversionista-detalles :row="row"></contrato-inversionista-detalles>
        <!-- El botón de "ver como", únicamente lo puede usar el usuario con rol "admin" -->
      </template>
    </b-table>
    <loading v-else></loading>

    <!-- Rentas -->
    <b-modal
      v-model="showRentasModal"
      centered
      size="lg"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      dialog-class="x-modal-md"
    >
      <template #modal-title>
        <span >Proyección de rentas</span>
      </template>

      <x-alert-with-errors
        :error="response.error"
        :title="response.message"
        :errors="response.errors"
      />

      <b-overlay :show="isLoading">
        <template #overlay>
          <div class="text-center">
            <loading message="Procesando información, por favor espere..." />
          </div>
        </template>

        <b-card :class="{ isLoading }">
          <b-table
            id="contratoRentasTable"
            v-if="!isLoading"
            :fields="fieldsRentas"
            :items="getResourceRentas"
            striped
            bordered
            responsive
            hover
            stacked="lg"
            show-empty
            small
          >
          </b-table>
          <loading v-else></loading>
        </b-card>
      </b-overlay>

      <template #modal-footer>
        <!-- Enviar para crear o actualizar -->
        <div class="w-100">
          <x-form-footer-buttons
            :loading="isLoading"
            @on-cancel="hideRentas"
            btn-cancel-title="Cerrar"
          ></x-form-footer-buttons>
        </div>
      </template>
    </b-modal>
    <!--div v-if="isThereData">
      <paginator
        aria-controls="usersTable"
        :disabled="!getAvailableList"
        show-path="userModule/getUsers"
        :resource="getResourceList"
      ></paginator>
    </div-->
    <!-- End Table -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ContratoInversionistaDetalles from './ContratoInversionistaDetalles'

const STORE_MODULE = 'contratosInversionistasModule'

const response = () => ({
  error: false,
  message: '',
  errors: {}
})

export default {
  name: 'ContratoInversionistaList',

  components: {
    ContratoInversionistaDetalles
  },

  created () {
    this.submit()
  },

  data () {
    return {
      selectedId: null,

      showRentasModal: false,

      isLoading: false,

      response: response(),

      fields: [
        { key: 'folio', label: 'Folio', sortable: true },
        { key: 'fecha_apertura', label: 'Fecha apertura', sortable: true },
        { key: 'fecha_vencimiento', label: 'Fecha vigencia', sortable: true },
        { key: 'inversionista.persona.nombre_completo', label: 'Inversionista', sortable: true },
        { key: 'show_details', label: 'Detalle' },
        { key: 'show_rentas', label: 'Rentas' },
        { key: 'acciones', label: 'Acciones' }
      ],

      fieldsRentas: [
        { key: 'periodo', label: 'Período' },
        { key: 'estatus', label: 'Estatus' },
        { key: 'renta', label: 'Renta' },
        { key: 'factura', label: 'Factura' },
        { key: 'pagada', label: 'Pagada' }
      ]
    }
  },

  computed: {
    ...mapGetters(STORE_MODULE, [
      'getResourceList',
      'getAvailableList',
      'isThereData',
      'getResource',
      'getResourceRentas'
    ])
  },

  methods: {
    async submit () {
      const { error, message } = await this.$store.dispatch(`${STORE_MODULE}/getResource`)

      if (error) this.$notify({ error, message }, 'Contratos')
    },

    async showRentas (id) {
      this.showRentasModal = true
      this.isLoading = true
      await this.$store.dispatch(`${STORE_MODULE}/getRentas`, id)
      this.isLoading = false
    },

    hideRentas () {
      this.showRentasModal = false
    },

    emitOnGotItem () {
      this.$emit('on-got-item')
    }
  }
}
</script>

<style scoped>

</style>
