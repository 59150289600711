<template>
  <div>
    <b-card
      class="mb-1"
      border-variant="primary"
      header-bg-variant="primary"
      header-text-variant="white"
      header="Detalle"
      no-body
    >
      <b-card-body>
        <b-row class="mb-2">
          <b-col cols="12" md="6">
            <b-card
              header="Datos del contrato"
              no-body
              border-variant="primary"
              class="mb-4"
              style="height: 500px; overflow: auto;"
            >
              <b-list-group flush>
                <b-list-group-item>
                  <b>Folio:</b>
                  {{ row.item.folio }}
                </b-list-group-item>

                <b-list-group-item>
                  <b>Fecha apertura:</b>
                  {{ row.item.fecha_apertura }}
                </b-list-group-item>

                <b-list-group-item>
                  <b>Fecha vencimiento:</b>
                  {{ row.item.fecha_vencimiento }}
                </b-list-group-item>

                <b-list-group-item>
                  <b>Fecha firma:</b>
                  {{ row.item.fecha_firma }}
                </b-list-group-item>

                <b-list-group-item>
                  <b>Forma de pago:</b>
                  {{ row.item.forma_pago }}
                </b-list-group-item>

                <b-list-group-item>
                  <b>Fideicomiso:</b>
                  {{ row.item.fideicomiso }}
                </b-list-group-item>

                <b-list-group-item>
                  <b>Meses desfase:</b>
                  {{ row.item.desfase }}
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
          <b-col cols="12" md="6">
            <b-card
              no-body
              header="Inversionista/Propiedades"
              header-bg-variant="warning"
              header-text-variant="light"
              border-variant="warning"
              style="height: 500px; overflow: auto;"
            >
              <b-list-group flush>
                <b-list-group-item>
                  <b>Inversionista:</b>
                  {{ row.item.inversionista.persona.nombre_completo }}
                </b-list-group-item>

                <b-list-group-item>
                  <b>Correo:</b>
                  {{ row.item.inversionista.email }}
                </b-list-group-item>

                <b-list-group-item>
                  <b>Propiedades:</b>
                  <ul>
                    <li v-for="p in row.item.propiedades" :key="p.id">
                      {{ p.edificio }} - {{ p.no_puerta }}
                    </li>
                  </ul>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
        </b-row>

        <b-card no-body>
          <b-button
            variant="white"
            size="sm"
            class="btn-outline-black"
            @click="row.toggleDetails"
          >
            Cerrar detalle
          </b-button>
        </b-card>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'ContratoInversionistaDetalles',

  props: {
    row: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
