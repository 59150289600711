<template>
  <base-view title="Mis Contratos" icon="file-text">
    <div>
      <check-authorization :requiresAuthorizations="['inversionistas indice contratos']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Contratos

            <div class="w-50 ml-auto d-flex justify-content-end align-items-center">
              <!-- Form -->
                <contrato-inversionista-form
                  :value="showForm || isEditingResource"
                  @input="showForm = $event"
                />
              <!-- Search -->
              <contrato-inversionista-search></contrato-inversionista-search>
              <!-- Options -->
              <div class="dropdown no-caret">
                <button
                  class="btn btn-transparent-dark btn-icon dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  <i data-feather="more-vertical"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right animated--fade-in-up">
                  <a class="dropdown-item my-cursor" @click="getUsers(null)">
                    Recargar
                  </a>
                </div>
              </div>
            </div>
          </b-card-header>

          <b-card-body class="p-2">
            <contrato-inversionista-list @on-got-item="showForm = true"></contrato-inversionista-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import ContratoInversionistaForm from '@/components/Inversionistas/Contratos/ContratoInversionistaForm'
import ContratoInversionistaList from '@/components/Inversionistas/Contratos/ContratoInversionistaList'
import ContratoInversionistaSearch from '@/components/Inversionistas/Contratos/ContratoInversionistaSearch'
import { mapActions, mapGetters } from 'vuex'

const STORE_MODULE = 'contratoModule'

export default {
  name: 'ContratoInversionistasView',

  components: {
    ContratoInversionistaForm,
    ContratoInversionistaList,
    ContratoInversionistaSearch
  },

  data () {
    return {
      hideButton: false,
      showForm: false
    }
  },

  computed: mapGetters(STORE_MODULE, ['isEditingResource']),

  methods: {
    ...mapActions(STORE_MODULE, ['getResources'])
  }
}
</script>

<style scoped>

</style>
